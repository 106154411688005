<template>
  <page-content :page-title="pageTitle">
    <template #actions>
      <region-chooser
        v-if="showRegionChooser && $store.getters['auth/isSuperAdmin']"
        @region:chosen="onRegionChosen"
      ></region-chooser>
      <el-button
        v-if="itemId"
        icon="fal fa-edit"
        style="margin-right: 5px;"
        @click="handleEdit(itemId)"
      >
        Bearbeiten
      </el-button>
      <el-dropdown trigger="click" @command="handleCommand">
        <el-button type="primary">
          Hinzufügen<i class="el-icon--right el-icon-arrow-down"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="newFolderCmd">Ordner</el-dropdown-item>
          <el-dropdown-item :command="newInstitutionCollectionCmd">
            Einrichtungssammlung
          </el-dropdown-item>
          <el-dropdown-item :command="newArticleCollectionCmd"
            >Artikelsammlung</el-dropdown-item
          >
          <el-dropdown-item :command="newLinkedItemCmd"
            >Verlinkung</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <el-tabs type="border-card">
      <el-tab-pane label="Elemente anzeigen">
        <el-table
          :key="itemId + '-' + items.length"
          v-loading="loading"
          :data="items"
        >
          <el-table-column label="Name" prop="name">
            <template #default="scope">
              <template v-if="scope.row.is_link">
                <i class="fa-link fal" style="margin-right: 4px;"></i>
                {{ scope.row.name }}
              </template>
              <template v-else-if="scope.row.type === 'articles'">
                <i class="fa-book fal" style="margin-right: 4px;"></i>
                {{ scope.row.name }}
              </template>
              <template v-else-if="scope.row.type === 'institutions'">
                <i class="fa-map-marker-alt fal" style="margin-right: 4px;"></i>
                {{ scope.row.name }}
              </template>
              <template v-else>
                <i class="fa-folder fal" style="margin-right: 4px;"></i>
                {{ scope.row.name }}
              </template>
            </template>
          </el-table-column>
          <el-table-column label="Inhalt" prop="type">
            <template #default="scope">
              <a
                v-if="scope.row.is_link"
                :href="
                  scope.row.url.startsWith('/')
                    ? `https://${scope.row.region_app_host}${scope.row.url}`
                    : scope.row.url
                "
                target="_blank"
              >
                {{
                  scope.row.url.startsWith("/") ? "Interne URL" : "Externe URL"
                }}
              </a>
              <el-button
                v-else-if="scope.row.type === 'articles'"
                size="mini"
                @click="showArticleCollection(scope.row.collection_id)"
              >
                Artikel verwalten
              </el-button>
              <el-button
                v-else-if="scope.row.type === 'institutions'"
                size="mini"
                @click="showInstitutionCollection(scope.row.collection_id)"
              >
                Einrichtungen verwalten
              </el-button>
              <el-button
                v-else-if="
                  scope.row.type === 'folder' &&
                    scope.row.is_link === false &&
                    scope.row.children.length > 0
                "
                size="mini"
                @click="showItem(scope.row.id)"
              >
                {{ scope.row.children.length }} Elemente verwalten
              </el-button>
              <el-dropdown v-else trigger="click" @command="handleCommand">
                <el-button type="primary" size="mini">
                  Hinzufügen<i class="el-icon--right el-icon-arrow-down"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="`newFolder::${scope.row.id}::${scope.row.name}`"
                  >
                    Ordner
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="
                      `newInstitutionCollection::${scope.row.id}::${scope.row.name}`
                    "
                  >
                    Einrichtungssammlung
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="
                      `newArticleCollection::${scope.row.id}::${scope.row.name}`
                    "
                  >
                    Artikelsammlung
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="
                      `newLinkedItem::${scope.row.id}::${scope.row.name}`
                    "
                  >
                    Verlinkung
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <el-table-column label="Veröffentlicht">
            <template #default="scope">
              <el-tag v-if="scope.row.published" size="small" type="success">
                Ja
              </el-tag>
              <el-tag v-else size="small" type="info">Nein</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Aktionen" width="300">
            <template #default="scope">
              <el-button
                icon="fal fa-edit"
                size="mini"
                @click="handleEdit(scope.row.id)"
              >
                Bearbeiten
              </el-button>

              <delete-button
                v-slot="slotProps"
                :on-delete="deleteItem"
                subject="Sammlung"
              >
                <el-button
                  size="mini"
                  icon="fal fa-trash-alt"
                  type="danger"
                  @click="slotProps.handleDelete(scope.row)"
                >
                  Löschen
                </el-button>
              </delete-button>

              <el-dropdown
                v-if="$store.getters['auth/isSuperAdmin']"
                trigger="click"
                style="margin-left: 10px;"
                @command="otherOptionsCommand"
              >
                <el-button icon="fal fa-ellipsis-h" size="mini"></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="`cloneItem::${scope.row.id}`">
                    Kopieren
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Elemente sortieren">
        <el-alert
          title="Hinweis"
          description="Die Elemente können per Drag & Drop verschoben und sortiert werden."
          type="info"
          show-icon
          style="margin-bottom: 20px;"
        >
        </el-alert>
        <el-tree
          :props="treeProps"
          :data="items"
          node-key="id"
          draggable
          :allow-drag="allowDrag"
          :allow-drop="allowDrop"
          @node-drop="handleDrop"
        >
          <span slot-scope="{ node, data }" class="el-tree-node__label">
            <template v-if="data.type === 'articles'">
              <i class="fa-book fal" style="margin-right: 4px;"></i>
              {{ node.label }}
            </template>
            <template v-else-if="data.type === 'institutions'">
              <i class="fa-map-marker-alt fal" style="margin-right: 4px;"></i>
              {{ node.label }}
            </template>
            <template v-else-if="data.is_link">
              <i class="fa-link fal" style="margin-right: 4px;"></i>
              {{ node.label }}
            </template>
            <template v-else-if="data.type === 'folder'">
              <i class="fa-folder fal" style="margin-right: 4px;"></i>
              {{ node.label }}
            </template>
            <template v-else-if="data.type === 'article'">
              <i class="fa-file fal" style="margin-right: 4px;"></i>
              {{ node.label }}
            </template>
          </span>
        </el-tree>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      title="Element kopieren"
      :visible.sync="dialogCloneItemVisible"
      width="500px"
    >
      <clone-guide-item-form
        :item-id="toCloneItemId"
        @dialog-closed="dialogCloneItemVisible = false"
      ></clone-guide-item-form>
    </el-dialog>
  </page-content>
</template>

<script>
import * as log from "loglevel"
import GuideItemsRepository from "@/repositories/guide_items_repository.js"
import ArticlesRepository from "@/repositories/articles_repository.js"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"
import DeleteButton from "@/components/DeleteButton.js"
import CloneGuideItemForm from "@/components/forms/CloneGuideItemForm"

export default {
  components: {
    PageContent,
    RegionChooser,
    DeleteButton,
    CloneGuideItemForm
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    onRegionChosen: {
      type: Function,
      default: () => {}
    },
    loading: {
      type: Boolean,
      required: true
    },
    showRegionChooser: {
      type: Boolean,
      default: false
    },
    pageTitle: {
      type: String,
      required: true
    },
    itemId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      dialogCloneItemVisible: false,
      toCloneItemId: null,
      treeProps: {
        label: "name"
      }
    }
  },
  computed: {
    newFolderCmd() {
      return this.itemId === 0
        ? "newFolder"
        : `newFolder::${this.itemId}::${this.pageTitle}`
    },
    newInstitutionCollectionCmd() {
      return this.itemId === 0
        ? "newInstitutionCollection"
        : `newInstitutionCollection::${this.itemId}::${this.pageTitle}`
    },
    newArticleCollectionCmd() {
      return this.itemId === 0
        ? "newArticleCollection"
        : `newArticleCollection::${this.itemId}::${this.pageTitle}`
    },
    newLinkedItemCmd() {
      return this.itemId === 0
        ? "newLinkedItem"
        : `newLinkedItem::${this.itemId}::${this.pageTitle}`
    }
  },
  async created() {},
  methods: {
    handleEdit(id) {
      this.$router.push({ name: "EditGuideItemPage", params: { id: id } })
    },
    async deleteItem(item) {
      this.items.splice(this.items.indexOf(item), 1)
      await GuideItemsRepository.destroy(item.id)
    },
    async handleDrop(draggingNode, dropNode, dropType) {
      if (draggingNode.data.type === "article") {
        log.info("Artikel in andere Artikelsammlung verschieben")
        ArticlesRepository.update(draggingNode.data.id, {
          article_collection_id: dropNode.data.collection_id
        })
      } else {
        if (dropType === "inner") {
          log.info("Direkt in einen Node verschieben")
          draggingNode.data.guide_item_id = dropNode.data.id
          GuideItemsRepository.update(draggingNode.data.id, {
            guide_item_id: dropNode.data.id
          })
        } else {
          if (draggingNode.data.guide_item_id === dropNode.data.guide_item_id) {
            log.info("Innerhalb desselben Parents umsortieren")
            const item_ids = dropNode.parent.childNodes.map(
              node => node.data.id
            )
            GuideItemsRepository.sort(item_ids)
          } else {
            if (dropType === "after") {
              log.info("Nach einen Node eines anderen Parents setzen")
              draggingNode.data.guide_item_id =
                dropNode.data.guide_item_id || null
              await GuideItemsRepository.update(draggingNode.data.id, {
                guide_item_id: dropNode.data.guide_item_id || null
              })
              const item_ids = dropNode.parent.childNodes.map(
                node => node.data.id
              )
              GuideItemsRepository.sort(item_ids)
            } else {
              if (dropNode.level === 1) {
                log.info("Node ins Top-Level vor einen anderen Node schieben")
                draggingNode.data.guide_item_id =
                  dropNode.data.guide_item_id || null
                await GuideItemsRepository.update(draggingNode.data.id, {
                  guide_item_id: dropNode.data.guide_item_id || null
                })
                const item_ids = this.items.map(item => item.id)
                GuideItemsRepository.sort(item_ids)
              } else {
                log.info("Vor einen Node eines anderen Parents setzen")
                draggingNode.data.guide_item_id =
                  dropNode.data.guide_item_id || null
                await GuideItemsRepository.update(draggingNode.data.id, {
                  guide_item_id: dropNode.data.guide_item_id || null
                })
                const item_ids = dropNode.parent.childNodes.map(
                  node => node.data.id
                )
                GuideItemsRepository.sort(item_ids)
              }
            }
          }
        }
      }
    },
    allowDrag(node) {
      return node.data.type !== "article"
    },
    allowDrop(draggingNode, dropNode, dropType) {
      if (dropType === "inner") {
        return dropNode.data.type === "folder" && dropNode.data.is_link == false
      } else {
        return true
      }
    },
    handleCommand(cmd) {
      let route = {
        name: "NewGuideItemPage",
        query: {}
      }

      if (cmd.includes("newFolder")) {
        if (cmd.includes("::")) {
          route.query.guide_item_id = cmd.split("::")[1]
          route.query.parentName = cmd.split("::")[2]
        }
      } else if (cmd.includes("newArticleCollection")) {
        if (cmd.includes("::")) {
          route.query.guide_item_id = cmd.split("::")[1]
          route.query.parentName = cmd.split("::")[2]
        }
        route.query.addCollection = "articles"
      } else if (cmd.includes("newInstitutionCollection")) {
        if (cmd.includes("::")) {
          route.query.guide_item_id = cmd.split("::")[1]
          route.query.parentName = cmd.split("::")[2]
        }
        route.query.addCollection = "institutions"
      } else if (cmd.includes("newLinkedItem")) {
        route.query.is_link = true
        if (cmd.includes("::")) {
          route.query.guide_item_id = cmd.split("::")[1]
          route.query.parentName = cmd.split("::")[2]
        }
      }

      this.$router.push(route)
    },
    otherOptionsCommand(cmd) {
      if (cmd.includes("cloneItem")) {
        if (cmd.includes("::")) {
          this.toCloneItemId = cmd.split("::")[1]
          this.dialogCloneItemVisible = true
        }
      }
    },
    showItem(itemID) {
      this.$router.push({ name: "ShowGuideItemPage", params: { id: itemID } })
    },
    showArticleCollection(id) {
      this.$router.push({
        name: "ShowArticleCollectionPage",
        params: { id: id }
      })
    },
    showInstitutionCollection(id) {
      this.$router.push({
        name: "ShowInstitutionCollectionPage",
        params: { id: id }
      })
    }
  }
}
</script>
